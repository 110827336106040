import "./styles.css";
// import {useEffect} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import AOS from "aos";
import 'aos/dist/aos.css';
import { Carousel } from "react-responsive-carousel";
import Footer from "./Footer";

//images
import preWed from "../assets/imgs/couple_together.jpg";
import couples from "../assets/imgs/couple-serious.jpg";
import groom from "../assets/imgs/groom-opt2.jpg";
import bride from "../assets/imgs/bride-opt-2.jpg";
import happy from "../assets/imgs/story2.jpg";
import groom2 from "../assets/imgs/groom-south.jpg";
import couple5 from "../assets/imgs/couple-50.jpg";
import coupleLaugh from "../assets/imgs/couple-laugh.jpg";
import coupleLove from "../assets/imgs/couple-love.jpg";
import travel from "../assets/imgs/couple-village.jpg";
import brideFine from "../assets/imgs/bride-fine.jpg";
import blackLove from "../assets/imgs/black-love.jpg";
import brideDate from "../assets/imgs/bride-date.jpg";
import coupleChair from '../assets/imgs/couple-chair.jpg';
import coupleFrown from "../assets/imgs/couple-frown.jpg";
import coupleFun from "../assets/imgs/couple-fun.jpg";
import coupleStrong from "../assets/imgs/couple-strong.jpg";
import dateNight from "../assets/imgs/date.jpg";
import gettingMarried from "../assets/imgs/getting-married.jpg";
import groomDate from "../assets/imgs/groom-date.jpg";
import { Helmet } from "react-helmet";

const OurGallery = () => {
     return (
        <>
          <Helmet>
            <meta name="description" content="photos of stephen and bruwaa"/>
            <title> 
                Stephen & Bruwaa Photos
            </title>
          </Helmet>

         <section className="caro-cont bg-burgandy overflow-hidden" aria-label="couple photo album">
            <div className="container w-5/6 mx-auto p-6 lg:h-screen" >
                <h1 className="text-center py-5  text-6xl md:text-7xl text-white tracking-wide">
                     Gallery
                </h1>
                <div className="carousel pb-5">
                    <Carousel>
                       <div className="">
                            <img src={gettingMarried} alt="stephen and bruwaa in love" />
                        </div>
                        <div className="">
                            <img src={preWed} alt="stephen and bruwaa pre wedding shoot" />
                        </div>
                      
                        <div className="">
                            <img src={couples} alt="stephen bruwaa couples inlove, stephen ntim and winnifred bruwaa look excited in their new photo" />
                        </div>
                        <div className="">
                            <img src={blackLove} alt="stephen and bruwaa looking elegant in their prewedding photoshoot" />
                        </div>
                        <div className="">
                            <img src={coupleLaugh} alt="couple laughing stephen ntim winnifred bruwaa" />
                        </div>
                        <div className="">
                            <img src={coupleStrong} alt="couple looking powerful" />
                        </div>
                        <div className="">
                            <img src={coupleChair} alt="stephen and bruwaa pre wedding photoshoot" />
                        </div>
                        
                        <div className="">
                            <img src={coupleFrown} alt="power couple stephen and bruwaa. Winnifred Bruwaa and Stephen Ntim look dazzling in their new picture" />
                        </div>
                        <div className="">
                            <img src={coupleLove} alt="couple in love" />
                        </div>
                        <div className="">
                            <img src={brideDate} alt="bruwaa dazzling on a date with stephen" />
                        </div>
                        <div className="">
                            <img src={groomDate} alt="stephen dazzling on a date with bruwaa" />
                        </div>
                        <div className="">
                            <img src={dateNight} alt="stephen and bruwaa on a date" />
                        </div>
                        <div className="">
                            <img src={bride} alt="stephen bruwaa the queen" />
                        </div>
                        <div className="">
                            <img src={groom} alt="the gentleman stephen" />
                        </div>


                        <div className="">
                            <img src={happy} alt="couple stephen and bruwaa happy. Stephen ntim and Winnifred bruwaa smile in thier new photo" />
                        </div>

                        <div className="">
                            <img src={couple5} alt="stephen and bruwaa at an event before their wedding." />
                        </div>
                        <div className="adjust">
                            <img src={brideFine} alt="bride shinning" />
                        </div>

                        <div className="adjust">
                            <img src={travel} alt="couple travellin" />
                        </div>

                         <div className="">
                            <img src={coupleFun} alt="stephen and bruwaa having fun before weddding" />
                        </div>

                        <div className="">
                            <img src={groom2} alt="groom happy. Stephen Ntim looks happy in new photo" />
                        </div>

                      

                    </Carousel>
                </div>
            </div>
            <Footer type="normal"/>
        </section>
        </>
      
       
        
     )
}

export default OurGallery;