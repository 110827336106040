import tvImg from "../assets/imgs/tv.png";
import "./styles.css";
import Countdown from "react-countdown";
import Footer from "./Footer";
import {CountDownRender2} from "./CountdownRenders";
import { WEDDING_DATE as weddingDate } from "../globals";
// import { ENGAGEMENT_DATE as engagementDate } from "../globals";
import { Helmet } from "react-helmet";

const WatchLive = () => {

    return (
        <>
            <Helmet>
                <meta name="keywords" content="Watch live stephen and bruwaa wedding"/>
                <title>
                    Watch Stephen-Bruwaa Live
                </title>
                <meta name="description" content="live Watch live of stephen and bruwaa"/>
            </Helmet>
            <section className="burgandyBehind" aria-label="countdown to wedding Watch live">
             <div className="live-countdown bg-burgandy opacity-90 h-auto text-white flex flex-col items-center justify-center w-full mx-auto p-3 pt-8 space-y-6 short:h-auto">
              
                <div className="text ">
                    <h1 className=" text-2xl font-poppins mb-7"> 
                        LIVE WITH STEPHEN & BRUWAA
                    </h1>
                </div>

                {/* Engagement Ceremony */}

                {/* <h1 className=" text-md  -translate-y-2/3 text-center font-poppins"> 
                        ENGAGEMENT CEREMONY
                </h1>

                <a
                 href="https://web.facebook.com/100007654426811/videos/1664644800717887/?__tn__=-R" 
                 target="blank"
                 className="live flex -translate-y-3 hover:opacity-80 items-center cursor-pointer justify-center border-2 px-4 py-1 rounded-md border-white">
                     <span> Watch Live</span>
                    <img
                      src={tvImg} 
                      alt="live tv" 
                      className=" p-2 align-middle object-cover"
                      />
                </a> 
                <p className="underline -translate-y-1/2 ">
                    click watch live to watch  
                </p>

                <Countdown date={engagementDate} renderer={CountDownRender2}/>

                <div className="line break bg-white h-1 w-3/4">

                </div> */}

                {/* Wedding Ceremony */}

                <h1 className=" text-md   text-center font-poppins"> 
                        RECEPTION CEREMONY
                </h1>

                <a
                 href="https://web.facebook.com/100007654426811/videos/6631933570152502/?__tn__=-R" 
                 target="blank"
                 className="live flex  hover:opacity-80 items-center cursor-not-allowed justify-center border-2 px-4 py-1 rounded-md border-white">
                     <span> Watch Live</span>
                    <img
                      src={tvImg} 
                      alt="live tv" 
                      className=" p-2 align-middle object-cover"
                      />
                </a> 
                <p className="underline  ">
                    Watch live link will be available in 
                </p>

                <Countdown date={weddingDate} renderer={CountDownRender2}/>
           

                {/* Loading */}
                <div className="">

                </div> 
                <div className="loading w-5/6 flex mx-auto items-center justify-center">
                    <span className="w-2 h-2 p-2 bg-white block rounded-full m-1"></span>
                    <span className="w-2 h-2 p-2 bg-white block rounded-full m-1"></span>
                    <span className="w-2 h-2 p-2 bg-white block rounded-full m-1"></span>
                    <span className="w-2 h-2 p-2 bg-white block rounded-full m-1"></span>
                    <span className="w-2 h-2 p-2 bg-white block rounded-full m-1"></span>
                    <span className="w-2 h-2 p-2 bg-white block rounded-full m-1"></span>
                    <span className="w-2 h-2 p-2 bg-white block rounded-full m-1"></span>
                    <span className="w-2 h-2 p-2 bg-white block rounded-full m-1"></span>
                </div>
             </div>
           
             {/* <iframe src="https://web.facebook.com/plugins/video.php?href=https%3A%2F%2Fweb.facebook.com%2F100007654426811%2Fvideos%2F1188404935210248%2F&width=1280" 
               width="1280" 
               height="720" 
                
               scrolling="no" 
               frameborder="0" 
               allowfullscreen="true" 
               allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
               allowFullScreen="true">

               </iframe> */}
             
             <Footer type="reverse"/>
            </section>
        </>
      
    )
}

export default WatchLive;