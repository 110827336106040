import "./../styles.css";
import preWed from "../../assets/imgs/couple_together.jpg";
import couples from "../../assets/imgs/couple-serious.jpg";
import groom from "../../assets/imgs/groom-opt2.jpg";
import excite from "../../assets/imgs/story3.jpg";
import bride from "../../assets/imgs/bride-opt-2.jpg";
import bride2 from "../../assets/imgs/bride-opt-3.jpg";
import happy from "../../assets/imgs/story2.jpg";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

const Gallery = () => {
    useEffect(()=>{
        AOS.init();
    },[]);
    return (
        <>
            <Helmet>
                <meta name="description" content="images, pictures of stephen and bruwaa"/>
                <meta name = "keywords" content="pictures of stephen and bruwaa stephen ntim Winnifred Bruwaa"/>
            </Helmet>
             <div className="gallery bg-white">
            <div className="container w-5/6 mx-auto pt-5 ">
                <div className="heading ">
                    <h1 className="text-center py-5 my-5 text-6xl md:text-7xl text-burgandy tracking-wide" data-aos="fade-up">
                        OUR GALLERY
                    </h1>
                </div>

                {/* GALLERY */} 
                <div className="grid_cont lg:h-[80vh] pb-5" data-aos="fade-up">
                   <div className="one">
                        <img src={excite} alt="soon to be"  className="max-h-60 md:max-h-full"/>
                    </div>
                    <div className="two">
                      <img src={groom} alt="husband Stephen"  className="max-h-60 md:max-h-full"/>
                    </div>
                    <div className="three">
                        <img src={bride2} alt="wife"  className="max-h-60 md:max-h-full"/>
                    </div>
                    <div className="four">
                         <img src={couples} alt="Stephen and Bruwaa happy"  className="max-h-60 md:max-h-full"/>
                    </div>
                    <div className="five">
                       
                         <img src={happy} alt="joyous couple stephan and bruwaa" className="max-h-60 md:max-h-full" />
                    </div>
                    <div className="six">
                        <img src={preWed} alt="stephen and bruwaa prewedding photoshoot"  className="max-h-60 md:max-h-full" />  
                    </div>   
                    <div className="seven">
                        <img src={bride} alt="Bruwaa the beautiful bride"  className="max-h-60 md:max-h-full"/>
                    </div>
                </div>
            </div>
             </div> 
        </>
       
    )
}

export default Gallery;