import {Link,useLocation} from 'react-router-dom';
import "./styles.css";
import { useState,useEffect } from 'react';
import wedIconGold from "../assets/imgs/sb-logo-gold-white.png";
import Modal from './Modal';

const Navbar = ({bg}) =>{
    const [sideMenuOpen,setSideMenuOpen] = useState(false);
    
    const handleMobileMenu = () => {
        setSideMenuOpen(old=>!old);    
    }
    const [navBg,setNavBg] = useState("lightDark");
    const location = useLocation();
  
    const [showModal,setShowModal] = useState(false);
    const handleCancel = () => {
        setShowModal(false);
    } 
    const openModal = () => {
        setShowModal(true);
    }
    const handleMobileDonate = () => {
        handleMobileMenu();
        openModal();
    }

    useEffect(()=>{
       if(window.location.pathname==="/"){
         setNavBg("lightDark");
       }else{
        setNavBg("white")
       }
    },[location])

    return (
            <nav className={`bg-${navBg}`}>
                <div className={`relative container mx-auto ${sideMenuOpen?"pt-6 pr-6 ":"p-6"} `}>
                    {/* Flex container */}
                    <div className="flex items-center justify-between">
                            {/* Icon */}
                        <div className="logo w-1/4">
                            <Link to="/" className='block hover:opacity-80'>
                             <h1 className={`text-5xl sofia line-through  cursor-pointer ${navBg==="white"?"text-burgandy":"text-gold"}`}>
                                SB
                             </h1>    
                            </Link>
                            
                        </div>

                            {/* Menu Items */}
                        <div className= {` hidden md:flex items-center justify-between w-3/4 font-bold ${navBg==="white"?"text-burgandy":"text-white"}`}>
                            <Link to="" 
                                className={`link pb-2 t font-bold border-b-2 border-transparent ${navBg==="white"? "hover:border-burgandy":"hover:border-white"} `}
                            >
                                HOME
                            </Link>
                            <a href="/#groom-bride" 
                               className={`link pb-2 t font-bold border-b-2 border-transparent ${navBg==="white"? "hover:border-burgandy":"hover:border-white"} `}
                             >
                                GROOM & BRIDE
                            </a>
                            <a href="/#our-story"
                                className={`link pb-2 t font-bold border-b-2 border-transparent ${navBg==="white"? "hover:border-burgandy":"hover:border-white"} `}
                            
                            >
                                OUR STORY
                            </a>

                            <button 
                                onClick={openModal}
                                className={` hidden link pb-2 t font-bold border-b-2 border-transparent ${navBg==="white"? "hover:border-burgandy":"hover:border-white"} lg:block `}
                            
                            >
                                GIFTS
                            </button>
                            

                            <Link to="/watch-live" 
                                className={`link pb-2 t font-bold border-b-2 border-transparent ${navBg==="white"? "hover:border-burgandy":"hover:border-white"} `}
                             >
                                WATCH LIVE
                            </Link>
                            <Link to="/gallery" 
                                className={`link pb-2 t font-bold border-b-2 border-transparent ${navBg==="white"? "hover:border-burgandy":"hover:border-white"} `}
                            
                            >
                                OUR GALLERY
                            </Link>
                        </div>

                            {/* Hamburger Icon */}
                        <div className="w-3/4 md:hidden flex items-center justify-end">
                            <button
                                onClick={handleMobileMenu}
                                id="menu-btn" 
                                className={`block relative z-50 hamburger md:hidden focus-outline-none ${sideMenuOpen? 'open':""}`}
                            >
                                <span className={` hamburger-top ${navBg==="white" ? "bg-gold":"bg-white"}`}></span>
                                <span className={` hamburger-middle ${navBg==="white" ? "bg-gold":"bg-white"}`}></span>
                                <span className={` hamburger-bottom ${navBg==="white" ? "bg-gold":"bg-white"}`}></span>
                            </button>
                        </div>
                    
                        
                    </div>
                </div> 
             {/* Mobile Menu */}
             <div className="md:hidden relative">
                <div
                id="menu"
                className={` ${sideMenuOpen?"w-[100vw] ":"w-0"} mx-0 flex fixed h-screen overflow-hidden z-40 top-0 flex-col items-center py-8 space-y-6 font-bold bg-burgandy`}
                >
                    <div className="w-24">
                        <img src={wedIconGold} alt="wedding icon" className="max-w-full w-full" />
                    </div>
  
                    <Link
                         to="" 
                         onClick={()=>setSideMenuOpen(old=>!old)} 
                         className='link pb-2 text-white font-bold border-b-2 border-transparent hover:border-white'
                        >
                            HOME
                    </Link>

                    <a 
                        href="/#groom-bride" 
                        onClick={()=>setSideMenuOpen(old=>!old)} 
                        className='link pb-2 text-white font-bold border-b-2 border-transparent hover:border-white'
                    >
                            GROOM & BRIDE
                    </a>

                    <button 
                                onClick={handleMobileDonate}
                                className='link pb-2 text-white font-bold border-b-2 border-transparent hover:border-white'              
                            >
                                GIFTS
                     </button>

                    <a 
                        href="/#our-story" 
                        onClick={()=>setSideMenuOpen(old=>!old)}
                        className='link pb-2 text-white font-bold border-b-2 border-transparent hover:border-white'
                    >
                            OUR STORY
                    </a>

                    <Link
                      to="/watch-live" 
                      onClick={()=>setSideMenuOpen(old=>!old)} 
                      className='link pb-2 text-white font-bold border-b-2 border-transparent hover:border-white'
                    >
                        WATCH LIVE
                    </Link>
                    
                    <Link
                       to="/gallery" 
                       onClick={()=>setSideMenuOpen(old=>!old)} 
                       className='link pb-2 text-white font-bold border-b-2 border-transparent hover:border-white'
                    >
                        OUR GALLERY
                    </Link>
                </div>
             </div>

             {/* Donate Modal */}
              <Modal
                showModal={showModal}
                handleCancel={handleCancel}
              />
            </nav>
           
    )
}
export default Navbar;