import "./../styles.css";
import whatsappIcon from "../../assets/imgs/whatsapp.svg";
import doves from "../../assets/imgs/two-doves.png";
import {useRef,useState} from "react";
import emailjs from '@emailjs/browser';
import {
        MAILJSID,
        RSMAILTEMPID,
        MSMAILTEMPID,
        MAILPUBKEY
    } from "../../globals";

import {MessageSent,ErrorOccured} from "../MessageStatus"; 
import { Helmet } from "react-helmet";

const Rsvp = () => {
    
    //Rsvp
    const rsvpForm = useRef();
    const [rsvpBtnLoading,setRsvpBtnLoading] = useState(false);
    const [rsvpDeliveryNotice,setRsvpDeliveryNotice] = useState(false);
    const [isRsvpError,setIsRsvpError] = useState(false);

    const sendRsvpMail = (e) => {
        e.preventDefault();
        setRsvpBtnLoading(true)
        emailjs.sendForm(MAILJSID, RSMAILTEMPID, rsvpForm.current, MAILPUBKEY)
          .then((result) => {
              setRsvpBtnLoading(false);
              setRsvpDeliveryNotice(true);
              setTimeout(()=>setRsvpDeliveryNotice(false),[60000])
          }, (error) => {
              setIsRsvpError(true);
              setRsvpBtnLoading(false);
              setTimeout(()=>setIsRsvpError(false),[60000])
              console.log(error);
          });
    };

    //Message
    const msgForm = useRef();
    const [msgBtnLoading,setMsgBtnLoading] = useState(false);
    const [msgDeliveryNotice,setMsgDeliveryNotice] = useState(false);
    const [isMsgError,setIsMsgError] = useState(false);
    
    const sendMessage = (e) => {
        e.preventDefault();
        setMsgBtnLoading(true)
        emailjs.sendForm(MAILJSID, MSMAILTEMPID, msgForm.current, MAILPUBKEY)
          .then((result) => {
              setMsgBtnLoading(false);
              setMsgDeliveryNotice(true);
              setTimeout(()=>setMsgDeliveryNotice(false),[60000])
          }, (error) => {
              setIsMsgError(true);
              setMsgBtnLoading(false);
              setTimeout(()=>setIsMsgError(false),[60000])
              console.log(error);
          });
    };



    return (
        <>
            <Helmet>
                <meta name="description" content="stephen and bruwaa wedding rsvp invitation" />
                <meta name="keywords" content="stephen and bruwaa  rsvp invitation" />
                
            </Helmet>
            <div className="bg-lightBurgandy">
            <div className="container w-5/6 mx-auto py-5">

             <div className = "leading-loose py-5 mt-7 max-w-xl mx-auto m-4 p-10 rsvp-container relative rounded shadow-xl space-y-6 border bg-white">
                
                {/* RSVP FORM */}
                <form 
                  className = "space-y-6" 
                  ref={rsvpForm} 
                  onSubmit={sendRsvpMail}
                >

                    <div className=" p-3 text-center">
                        <h1 className="font-sofia p-2 font-bold text-4xl  text-burgandy">
                             RSVP 
                        </h1> 
                        <p className="font-poppins font-bold ">
                             Are You Attending ?
                        </p>
                    </div>
                    
                    {
                        rsvpDeliveryNotice ?
                        <MessageSent desc="Rsvp" />
                         :
                         isRsvpError ?
                         <ErrorOccured /> 
                          :
                         <div className="form-actions space-y-6">
                            <div>
                                <input 
                                    className="w-full px-5 py-2 text-gray-700 bg-gray-200 rounded" 
                                    id="user_name" 
                                    name="user_name" 
                                    type="text" 
                                    required="required" 
                                    placeholder="your name" 
                                    aria-label="Name"
                                />
                                <p className ="text-sm text-red-500 hidden mt-3" id="error">
                                    Please fill out this field.
                                </p>
                            </div>
                            <div>
                                <input 
                                        className="w-full px-5 py-2 text-gray-700 bg-gray-200 rounded"
                                        id="phone_num" 
                                        name="contact" 
                                        type="text"
                                        placeholder="Phone no. (optional)" aria-label="Name"
                                />
                             </div>
                        
                            <div className="w-full flex flex-col mb-3 mt-2">
                                <select 
                                    className="block  bg-grey-lighter text-grey-darker cursor-pointer border border-grey-lighter rounded-lg h-10 px-4 md:w-full " 
                                    required="required" 
                                    name="message" 
                                    id="rsvp message"
                                >
                                    <option value="" className="cursor-pointer">-- select number of guests --</option>
                                    <option value="1" className="cursor-pointer">1 Guest</option>
                                    <option value="3" className="cursor-pointer">3 Guests</option>
                                    <option value="5" className="cursor-pointer">5 Guests</option>
                                    <option value="5+" className="cursor-pointer">5+ Guests</option>
                                </select>
                                <p className ="text-sm text-red-500 hidden mt-3" id="error">
                                    Please Select Number of guests.
                                </p>
                            </div>
    
                            <div className="mt-4 flex items-center justify-center">
    
                            <button 
                                className={`px-7 py-1 text-white font-light tracking-wider bg-burgandy rounded hover:opacity-80 active:opacity-0" type=submit ${rsvpBtnLoading?"cursor-wait":"cursor-pointer"} `}
                                type="submit"
                            >
                                {rsvpBtnLoading?"Sending ...": "Send"}
                            </button>
                            </div>
                        </div>
                    }
                  
                    


                </form>

                <h1 className="text-center font-poppins">
                    Or
                </h1>
                <div className="contact">
                    <p className="text-center">
                        Call / Whatsapp
                    </p>
                    <div className="whatsapp_links flex items-center justify-center">
                        <a 
                         href="https://wa.me/233545818115"
                         target="_blank"
                         rel="noreferrer"
                         className="flex items-center pr-2 underline text-blue-600"
                         >
                            
                                <img 
                                    src={whatsappIcon} 
                                    alt="whatsapp" 
                                    className="w-6 h-6 object-cover align-middle"
                                />
                                <span> +233545818115 </span> 
                          
                        </a>
                      
                        <a 
                         href="https://wa.me/233542473119"
                         target="_blank"
                         rel="noreferrer"
                         className="flex items-center pl-4 underline text-blue-600"
                         >
                                <img 
                                    src={whatsappIcon} 
                                    alt="whatsapp" 
                                    className="w-6 h-6 object-cover align-middle"
                                />
                                <span> +233542473119 </span>
                            
                        </a> 

                    </div>

                </div>
             </div>

             {/* MESSAGE FORM */}

             <div className = "leading-loose py-5 mt-7 max-w-xl mx-auto m-4 p-10 rsvp-container relative rounded shadow-xl space-y-6 border bg-white">

                {/* MESSAGE FORM */}
                <form 
                    className = "space-y-3 "
                    ref={msgForm}
                    onSubmit={sendMessage}
                    >

                     <div className="form-img flex items-center justify-center">
                        <img 
                            src={doves} 
                            alt="two doves" 
                            className="w-16 h-16"
                        />
                     </div>

                     <p className="font-poppins font-bold text-center">
                          {
                            msgDeliveryNotice ? "Message Sent" : "Send Message To Couple"
                          }
                             
                     </p>

                    {
                        msgDeliveryNotice ?
                         <MessageSent desc=" Message "/>
                        :
                        isMsgError ?
                        <ErrorOccured />
                        :
                        <div className="form-actions w-full mx-auto space-y-6 ">

                        <div>
                            <input 
                                className="w-full px-5 py-2 text-gray-700 bg-gray-200 rounded"
                                 id="name" name="user_name" 
                                 type="text"
                                 required="required"
                                 placeholder="your name" aria-label="Name"
                            />
                            <p className ="text-sm text-red-500 hidden mt-3" id="error">
                                        Please fill out this field.
                            </p>
                        </div>
                        <div>
                        <input 
                                className="w-full px-5 py-2 text-gray-700 bg-gray-200 rounded"
                                 id="phone_num" 
                                 name="contact" 
                                 type="text"
                                 placeholder="Phone no. (optional)" aria-label="Name"
                            />
                        </div>
                        <div >
                            <textarea 
                                name="message" 
                                id="" 
                                cols="16" 
                                rows="4"
                                required="required"
                                placeholder="leave a message for the couple"
                                className="w-full bg-gray-200 p-2"
                            >
                            </textarea>
                            <p className ="text-sm text-red-500 hidden mt-3" id="error">
                                            Please add a message to couple.
                            </p>
                        </div>
                        
                        
                        <div className="mt-4 flex items-center justify-center">
    
                            <button 
                                className={`px-7 py-1 text-white font-light tracking-wider bg-burgandy rounded hover:opacity-80 active:opacity-0" type=submit ${msgBtnLoading?"cursor-wait":"cursor-pointer"} `}
                                type="submit"
                            >
                                {msgBtnLoading?"Sending ...": "Send"}
                            </button>
                         </div>

                    </div>
                    }
                   
                     

                </form>
              
             </div>
             
            </div>
        </div>
        </>
    
    )
}

export default Rsvp;