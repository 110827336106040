import { useState } from "react";
import kenteImg from "../../assets/imgs/kente.png";
import partyImg from "../../assets/imgs/wine.png";
import wedImg from "../../assets/imgs/wed.png";
import calendarImg from "../../assets/imgs/calendar.svg";
import clockImg from "../../assets/imgs/clock-regular.svg";
import locationImg from "../../assets/imgs/marker.svg";
import Donate from "../Donate";
import Modal from "../Modal";
import { Helmet } from "react-helmet";


const WeddingEvents = () => {
    const [showModal,setShowModal] = useState(false);
    const handleCancel = () => {
        setShowModal(false);
    } 
    const openModal = () => {
        setShowModal(true);
    }
    return(
        <>
         <Helmet>
            <meta name="keywords" content="wedding date and location" />
            <meta name="description" content="location and date of stephen and bruwaa wedding" />
         </Helmet>
         <section className="bg-zinc-100">
              
              {/* container */}
              <div className="container w-full mx-auto pt-5 pb-5 bg-white md:w-5/6 lg:pb-0 ">

                  {/* Intro */}
                <div className="intro space-y-6 p-5  lg:-mb-8">
                  <h1 className="font-poppins font-bold text-center p-2 pt-5 text-5xl text-burgandy my-5" >
                      WEDDING EVENTS
                  </h1>
                  <h2 className="text-center text-2xl text-darkGold font-bold tracking-widest lg:pt-8">
                      WHEN & WHERE
                  </h2>
                </div>

                   {/* Cards */}
               <div className="flex flex-col items-center">
                     
                 <div className="cards grid lg:grid-cols-3  gap-x-8 pb-2 w-5/6 space-y-6 lg:translate-y-1/3 lg:space-y-0 relative z-20">
                      {/* Traditional */}
                  <div className="card space-y-3 p-5 bg-zinc-100 shadow-lg rounded-sm ">
                      <div className="img-cont flex items-center justify-center">
                          <img 
                              src={kenteImg} 
                              alt="cake" 
                              className="w-10 h-10 align-middle object-cover"
                          />
                      </div>

                      <h1 className="text-2xl text-center font-serif font-bold p-2">
                          Traditional
                      </h1>
                      <div className="text-center  flex items-center justify-center font-poppins text-gray-800">
                          <img
                           src={calendarImg} 
                           alt="calendar"
                           className="w-5 h-5 p-0.5 object-cover align-middle"
                           />
                           <span>&nbsp;14th April 2023</span> 
                      </div>
                         
                      <div className="text-center  flex items-center justify-center text-gray-800">
                          <img
                           src={clockImg} 
                           alt="calendar"
                           className="w-4 h-4 p-0.5 object-cover align-middle"
                           />
                           <span>&nbsp;09 : 00 AM</span> 
                      </div>
                           {/* Address  */}
                      <div className=" flex flex-col items-center text-gray-800">
      
                                  
                          <p className="block w-3/5 text-center  tracking-normal font-bold md:w-4/5">
                               Oduom, Kumasi - Ghana.
                           </p>
                             
                      </div>
                  </div>
                      
                      {/* Wedding */}
                  <div className="card space-y-3 p-5 bg-zinc-100 shadow-lg rounded-sm">
                      <div className="img-cont flex items-center justify-center">
                          <img 
                              src={wedImg} 
                              alt="wedding reception" 
                              className="w-12 h-12 align-middle object-cover"  
                          />
                      </div>

                      <h1 className="text-2xl text-center font-serif font-bold p-2">
                          The Wedding
                      </h1>

                      <div className="text-center  flex items-center justify-center font-poppins text-gray-800">
                          <img
                           src={calendarImg} 
                           alt="calendar"
                           className="w-5 h-5 p-0.5 object-cover align-middle"
                           />
                           <span>&nbsp; 15th April 2023</span> 
                      </div>

                      <div className="text-center  flex items-center justify-center text-gray-800">
                          <img
                           src={clockImg} 
                           alt="calendar"
                           className="w-4 h-4 p-0.5 object-cover align-middle"
                           />
                           <span>&nbsp;10 : 30 AM</span> 
                      </div>
                     
                        {/* Address  */}
                      <div className=" flex flex-col items-center text-gray-800">
      
                      <a 
                           href="https://goo.gl/maps/UpL2Jb1Pmv3EDAY97"
                           target="_blank"
                           rel="noreferrer"
                           className="block"
                           >
                              <div 
                               className="text-center text-blue-700 underline flex items-center flex-col justify-center text-center tracking-wider hover:text-blue-500">
                                  <p className="flex items-center">
                                      <img
                                          src={locationImg} 
                                          alt="calendar"
                                          className="w-4 h-4  object-cover align-middle"
                                      />
                                      <span>&nbsp;Church Of Pentecost</span> 
                                  </p>
                                  
                                  <p className="block w-3/5 text-center  tracking-wider">
                                     B.K. Arthur Memorial Temple, Oduom - Kumasi.
                                   </p>
                              </div>
                              

                          </a>  

                      </div>

                  </div>

                      {/* The Reception  */}
                  <div className="card space-y-3 p-5 bg-zinc-100 shadow-lg rounded-sm ">
                      <div className="img-cont flex items-center justify-center">
                          <img 
                              src={partyImg} 
                              alt="cake" 
                              className="w-10 h-10 align-middle object-cover"
                          />
                      </div>
                      <h1 className="text-2xl text-center font-serif font-bold p-2">
                          The Reception
                      </h1>
                     
                     
                      <div className="text-center  flex items-center justify-center font-poppins text-gray-800">
                          <img
                           src={calendarImg} 
                           alt="calendar"
                           className="w-5 h-5 p-0.5 object-cover align-middle"
                           />
                           <span>&nbsp;15th April 2023</span> 
                      </div>
                      <div className="text-center  flex items-center justify-center text-gray-800">
                          <img
                           src={clockImg} 
                           alt="calendar"
                           className="w-4 h-4 p-0.5 object-cover align-middle"
                           />
                           <span>&nbsp;2 : 30 PM</span> 
                      </div>

                      <div className=" flex flex-col items-center text-gray-800">
      
                      <a 
                           href="https://goo.gl/maps/gyGSJ6FqTazNKrqp9"
                           target="_blank"
                           rel="noreferrer"
                           className="block"
                           >
                              <div 
                               className="text-center text-blue-700 underline flex items-center flex-col justify-center text-center tracking-wider hover:text-blue-500">
                                  <p className="flex items-center">
                                      <img
                                          src={locationImg} 
                                          alt="calendar"
                                          className="w-4 h-4  object-cover align-middle"
                                      />
                                      <span>&nbsp;Noda Hotel</span> 
                                  </p>
                                  
                                  <p className="block w-3/5 text-center  tracking-wider">
                                      3 Club St, Ejisu Off National Highway 6.
                                   </p>
                              </div>
                              

                          </a>  

                      </div>

                      
                  </div>

              </div>

               </div>
           

              </div>

              <div className="bg-burgandy h-24 w-full flex justify-center items:center  py-3 relative lg:h-64 lg:items-end lg:py-0 lg:pb-3">
                <Donate onClick={openModal}/>
              </div>
              <Modal
               showModal={showModal}
               handleCancel={handleCancel}
              />
          </section>
        </>
           
    )
}

export default WeddingEvents;