import { useEffect } from "react";
import "./../styles.css";
import brideImg from "../../assets/imgs/bride.jpg";
import groomImg from "../../assets/imgs/groom.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

const BrideGroom = () => {
    useEffect(()=>{
        AOS.init();
    },[])

    return(
        <section className="bg-zinc-100 overflow-hidden" id="groom-bride" aria-label="introduction of couple">
            <Helmet>
                <meta  name="description" content="About stephen Ntim and  Winnifred Bruwaa Stephen and Bruwaa" />
            </Helmet>
            {/* container */}
            <div className="container bg-white w-full mx-auto py-5 lg:h-screen md:w-5/6">

                  <div className="intro my-7" data-aos="fade-up">
                    <h1 className="font-poppins text-center py-3 text-4xl text-burgandy tracking-normal md:text-7xl md:pt-5 md:tracking-wide md:font-vibes">
                        Groom & Bride
                    </h1>
                  </div>
                 
                  <div className="bride-groom grid grid-cols-1  p-6 mx-auto pb-8 lg:pb-6 lg:grid-cols-2 lg:space-y-0" >

                    {/* Groom */}
                    <div className="groom-card" data-aos="fade-up">
                            <div className="img-cont flex items-center justify-center">
                                <img 
                                src={groomImg} 
                                alt="stephen" 
                                className="align-middle w-40 h-40 rounded-full object-cover relative z-10 lg:w-48 lg:h-48 "
                                />
                            </div>

                        <div className="text-content border-2 w-full  rounded-md mx-auto p-1 -translate-y-24 sm:w-5/6 md:w-4/6">
                            <div className="box w-full h-24">

                            </div>
                            <h1 className="font-poppins text-burgandy text-center text-xl">
                                STEPHEN NTIM
                            </h1>
                            <p className="text-center tracking-wide text-lg p-2 text-zinc-800">
                           Stephen is a Ghanaian from the Ashanti region.
                            He is the 2nd of 5 children. He holds BSc. Business Administration (Accounting) from Central University,
                            Accra, Ghana and an MBA (Finance) from University of Ghana.
                            Stephen works with Nestle.Stephen describes the encounter with Bruwaa as simply God sent.
                            </p>
                        </div>
                        
                    </div>    

                    {/* Bride */}
                    <div className="bride-card" data-aos="fade-up">
                        
                            <div className="img-cont flex items-center justify-center">
                                <img 
                                src={brideImg} 
                                alt="bruwaa" 
                                className="align-middle w-40 h-40 rounded-full relative z-10 object-cover lg:w-48 lg:h-48 "
                                />
                            </div>
                            
                            <div className="text-content border-2 w-full mx-auto p-1 -translate-y-24 rounded-md sm:w-5/6 md:w-4/6">
                                <div className="box w-full h-24">

                                </div>
                                <h1 className="font-poppins text-burgandy text-center text-xl">
                                    WINNIFRED BRUWAA BAFFOE
                                </h1>
                                <p className="text-center tracking-wide p-2 text-lg text-zinc-800">
                                Bruwaa is a Ghanaian from the Ashanti region.
                                She is the 3rd of 4 children. She holds BA. Economics from University of Cape Coast and an MSc. (Investment Management) from University of Liverpool, China campus.
                                Bruwaa works with Nestle. Bruwaa describes the encounter with Stephen as simply God ordained.

                                </p>
                        </div>
                     
                    </div>    

                  </div>
            </div>
        </section>
    )
}

export default BrideGroom;