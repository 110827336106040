import "./styles.css";
const Donate = ({onClick}) => {
    return (
        <button 
            onClick={onClick}
            className="btn2 w-64  px-10 py-5 relative border-2 border-white rounded-md  uppercase font-semibold tracking-wider  overflow-hidden hover:text-burgandy" type="button">
            <span className="absolute inset-0 bg-white"></span>
            <span className="absolute inset-0 flex justify-center items-center font-bold text-white hover:text-burgandy"> 
            Gifts
            </span>
           Gifts
        </button>
    
    )
}

export default Donate;