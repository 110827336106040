import "./../styles.css";
import { Helmet } from "react-helmet";

const ReceptionOutline = () => {
     return(
        <>
         <Helmet>
            <meta name="keywords" content="reception stephen-bruwaa" />
            <meta name = "description" content="wedding outline for stephen and bruwaa"/>
        </Helmet>
        <section className="reception-cont h-auto md:h-auto" aria-label="reception outline">
            <div className="container w-5/6 mx-auto py-5">
            <div className="reception-outline leading-loose py-5   mt-7 max-w-xl mx-auto m-4 p-10 relative rounded shadow-xl space-y-6  bg-white">
                <h1 className="text-3xl text-center font-poppins py-3 text-burgandy">
                    Reception Outline
                </h1>
                <ul className="list-disc list-inside pl-6 text-base text-slate-800">
                    <li className="p-1 font-poppins  border-burgandy">Guest Seated</li>
                    <li className="p-1 font-poppins  border-burgandy">Opening Prayer</li> 
                    <li className="p-1 font-poppins  border-burgandy">Arrival of Couple and Family</li>
                    <li className="p-1 font-poppins  border-burgandy">Introduction of Chairman and Other Dignitaries</li>
                    <li className="p-1 font-poppins  border-burgandy">Chairman's Welcome Address</li>
                    <li className="p-1 font-poppins  border-burgandy">Musical Interlude</li>
                    <li className="p-1 font-poppins  border-burgandy">Dine</li>
                    <li className="p-1 font-poppins  border-burgandy">Couple's First Dance</li> 
                    <li className="p-1 font-poppins  border-burgandy">Special Performance</li>
                    <li className="p-1 font-poppins  border-burgandy">Fun Time with Couple</li>
                    <li className="p-1 font-poppins  border-burgandy">SHE - Ergonomics</li>
                    <li className="p-1 font-poppins  border-burgandy">Cake and Champagne</li>
                    <li className="p-1 font-poppins  border-burgandy">Dance</li>
                    <li className="p-1 font-poppins  border-burgandy">Chairman's Closing Remarks</li>
                    <li className="p-1 font-poppins  border-burgandy">Vote of thanks</li>
                    <li className="p-1 font-poppins  border-burgandy">Closing Prayer</li>
                </ul>
             </div>
            </div> 
            
        </section>
        </>
       
     ) 
}
export default ReceptionOutline;