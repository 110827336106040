import blueTick from "../assets/imgs/check-double-solid.svg";
import errorImg from "../assets/imgs/circle-exclamation-solid.svg";

export const MessageSent = ({desc}) => {
    return(
        <div className="bg-white flex items-center justify-center space-x-3">
            <h1 className="font-poppins text-green-600">
                {desc} delivered successfully
            </h1>
            <div className="img">
                <img 
        
                 src={blueTick} 
                 alt="message sent"
                 className="w-6 h-6 object-cover align-middle"
                  />
            </div>
        </div>
    )
}

export const ErrorOccured = () => {

    return(
        <div className="bg-white flex items-center justify-center space-x-3">
            <h1 className="font-poppins text-red-600">
                An Error Occured. 
                Refresh page and try again..
            </h1>
            <div className="img">
                <img 
                  
                  src={errorImg} 
                  alt="message sent"
                  className="w-6 h-6 object-cover align-middle"
                  />
            </div>
        </div>
    )
}