import firstMeet from "../../assets/imgs/story5.jpg";
import secondMeet from "../../assets/imgs/story2.jpg";
import thirdMeet from "../../assets/imgs/story1.jpg";
import finalSay from "../../assets/imgs/getting-married.jpg";
import { Helmet } from "react-helmet";
import AOS from "aos";
import { useEffect } from "react";
import "aos/dist/aos.css";

const OurStory = () => {
    useEffect(()=>{
        AOS.init();
    },[])
    return (
        <>
        <Helmet>
            <meta name="description" content="the story of stephen and bruwaa" />
        </Helmet>
         <section className="bg-white overflow-hidden" id="our-story" aria-label="story of couple">
              
              <div className="bg-white ">
                {/* container */}
                <div className="container space-y-12 bg-zinc-100  w-full mx-auto py-5 md:w-5/6 ">

                    <div className="heading">
                        <h1 className="font-poppins font-bold text-center pt-5 text-5xl text-burgandy ">
                            OUR STORY
                        </h1>
                    </div>

                    {/* Stories Container */}

                    <div className="story mt-8 flex flex-col space-y-6">

                         {/* Story 1 */}
                        <div className="flex flex-col-reverse items-center justify:center lg:flex-row" data-aos="fade-left">

                            <div className="rec1 w-5/6 mt-2 -translate-y-5 lg:mt-0 lg:flex lg:items-center lg:justify-center lg:w-[45%] lg:translate-y-0">

                                <div className="text-wrap w-full text-center rounded-sm  bg-white shadow-sm p-2 lg:shadow-none lg:border-2 lg:border-lightDark lg:bg-transparent lg:text-right lg:w-4/6">
                                    <h2 className="text-darkGold pt-2 md:pt-0">August 24th, 2017</h2>
                                    <h1 className="font-bold">First We Met</h1>
                                    <p className="text-gray-700 text-center lg:text-right">
                                        A soothing mid morning encounter in a very serious and life changing setting. 
                                    </p>
                                </div>

                            </div>


                            <div className="circle-cont w-fit lg:w-[10%] relative ">
                                <div className="circle align-middle bg-burgandy p-2 w-32 h-32 rounded-full object-cover" >
                                    <img 
                                    src={firstMeet} 
                                    alt="couple first meeting" 
                                    className="align-middle w-28 h-28 rounded-full object-cover"
                                    />
                                </div>
                            </div>


                            <div className="rec2 border-burgandy  w-[45%] border-2 opacity-0 hidden lg:block">

                            </div>

                        </div>

                        {/* Story 2 */}

                        <div className="flex flex-col items-center justify:center lg:flex-row" data-aos="fade-left">

                            <div className="rec2 border-burgandy  w-[45%] border-2 opacity-0 hidden lg:block">

                            </div>

                            <div className="circle-cont w-fit relative z-10 lg:w-[10%]">
                                <div className="circle align-middle bg-burgandy p-2 w-32 h-32 rounded-full object-cover" >
                                    <img 
                                    src={secondMeet} 
                                    alt="stephen and bruwaa first meeting" 
                                    className="align-middle w-28 h-28 rounded-full object-cover"
                                    />
                                </div>
                            </div>

                            <div className="rec1 w-5/6 mt-2 -translate-y-5 lg:translate-y-0 lg:mt-0 lg:flex lg:items-center lg:justify-center lg:w-[45%]">

                                <div 
                                    className="text-wrap w-full text-center rounded-sm  bg-white shadow-sm p-2  lg:shadow-none lg:border-2 lg:border-lightDark lg:bg-transparent lg:text-right lg:w-4/6">
                                    <h2 className="text-darkGold pt-2 md:pt-0">July 28, 2019</h2>
                                    <h1 className="font-bold">We fell in love</h1>
                                    <p className="text-gray-700 text-center lg:text-left">
                                        We grew together and fell in love with each other.
                                        You became my world, I became your everything.
                                    </p>
                                </div>

                            </div>


                        

                        </div>

                        {/* Story 3 */}

                        <div className="flex flex-col-reverse items-center justify:center lg:flex-row" data-aos="fade-up">

                            <div className="rec1 w-5/6 mt-2 -translate-y-5 lg:translate-y-0 lg:mt-0 lg:flex lg:items-center lg:justify-center lg:w-[45%]">

                                <div className="text-wrap w-full text-center rounded-sm  bg-white shadow-sm p-2 lg:shadow-none lg:border-2 lg:border-lightDark lg:bg-transparent lg:text-right lg:w-4/6">
                                    <h2 className="text-darkGold pt-2 md:pt-0">Febuary 14, 2020</h2>
                                    <h1 className="font-bold">She said Yes</h1>
                                    <p className="text-gray-700 text-center lg:text-right"> 
                                        My heart will always be yours and yours will always be mine. 
                                        Loving you is the best decision, I have ever taken.
                                    </p>
                                </div>

                            </div>


                            <div className="circle-cont w-fit lg:w-[10%] relative">
                                <div className="circle align-middle bg-burgandy p-2 w-32 h-32 rounded-full object-cover" >
                                    <img 
                                    src={thirdMeet} 
                                    alt="stephen bruwaa second meeting" 
                                    className="align-middle w-28 h-28 rounded-full object-cover object-top"
                                    />
                                </div>
                            </div>


                            <div className="rec2 border-burgandy  w-[45%] border-2 opacity-0 hidden lg:block">

                            </div>

                        </div>

                        {/* Story 4 */}

                        <div className="flex flex-col items-center justify:center lg:flex-row" data-aos="fade-up">

                            <div className="rec2 border-burgandy  w-[45%] border-2 opacity-0 hidden lg:block">

                            </div>

                            <div className="circle-cont w-fit lg:w-[10%] relative z-10">
                                <div className="circle align-middle bg-burgandy p-2 w-32 h-32 rounded-full object-cover" >
                                    <img 
                                    src={finalSay} 
                                    alt="stephen bruwaa third meeting" 
                                    className="align-middle w-28 h-28 rounded-full object-cover object-center"
                                    />
                                </div>
                            </div>

                            <div className="rec1 w-5/6 mt-2 -translate-y-5 lg:translate-y-0 lg:mt-0 lg:flex lg:items-center lg:justify-center lg:w-[45%]">

                                <div className="text-wrap w-full text-center rounded-sm  bg-white shadow-sm p-2 lg:shadow-none lg:border-2 lg:border-lightDark lg:bg-transparent lg:text-right lg:w-4/6">
                                    <h2 className="text-darkGold pt-2 md:pt-0">April 15, 2023</h2>
                                    <h1 className="font-bold">We are getting married</h1>
                                    <p className="text-gray-700 text-center lg:text-left">
                                         The final step in ensuring, we enjoy the rest of our lives together.
                                         We are meant for each other. I love us.
                                         Forever and Ever.
                                         #FinallyANtim #StephenWINNs
                                    </p>
                                </div>

                            </div>


                        

                        </div>

                    </div>

                </div>  
             </div>
        </section>
        </> 
    )
} 

export default OurStory;