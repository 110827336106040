export const CountDownRender1 = ({days,hours,minutes,seconds,completed}) => {
    return (
        <>
          {
            completed ? 
          <div className=" mt-8 p-3 timer w-5/6 flex items-center justify-between sm:w-4/6 md:w-2/6">
            <div className="count">
                <h1 className="text-2xl font-bold">00</h1>
                <p className="p-2">DAYS</p>
            </div>
            <div className="count">
                <h1 className="text-2xl font-bold">00</h1>
                <p className="p-2">HOURS</p>
            </div>
            <div className="count">
                <h1 className="text-2xl font-bold">00</h1>
                <p className="p-2">MINUTES</p>
            </div>
            <div className="count">
                <h1 className="text-2xl font-bold">00</h1>
                <p className="p-2">SECONDS</p>
            </div>
          </div>  
              :
             <div className=" mt-8 p-3 timer w-5/6 flex items-center justify-between sm:w-4/6 md:w-2/6">
                <div className="count">
                    <h1 className="text-2xl font-bold">{days}</h1>
                    <p className="p-2">DAYS</p>
                </div>
                <div className="count">
                    <h1 className="text-2xl font-bold">{hours}</h1>
                    <p className="p-2">HOURS</p>
                </div>
                <div className="count">
                    <h1 className="text-2xl font-bold">{minutes}</h1>
                    <p className="p-2">MINUTES</p>
                </div>
                <div className="count">
                    <h1 className="text-2xl font-bold">{seconds}</h1>
                    <p className="p-2">SECONDS</p>
                </div>
              </div> 
          }
       
       </>
        
    )
}

export const CountDownRender2 = ({days,hours,minutes,seconds,completed}) => {
    return (
        <>
          {
           completed ? 
           <div className="p-3 timer fit bg-burgandy  flex  items-center  -translate-y-4  border-white justify-between  ">
           <div className="flex items-center  w-24 flex-col">
               <h1 className="text-2xl font-bold w-10 overflow-hidden text-burgandy text-center bg-white rounded py-3 px-1">
                 00
               </h1>
               <p className="p-2">DAYS</p>
           </div>
           <div className="flex items-center  w-24 flex-col">
               <h1 className="text-2xl font-bold text-burgandy bg-white w-10 text-center overflow-hidden rounded py-3 px-1">
                  00
               </h1>
               <p className="p-2">HOURS</p>
           </div>
           <div className="flex items-center  w-24 flex-col">
               <h1 className="text-2xl font-bold text-burgandy bg-white w-10 text-center overflow-hidden rounded py-3 px-1">
                  00
               </h1>
               <p className="p-2">MINUTES</p>
           </div>

           <div className="flex items-center  w-24 flex-col">
               <h1 className="text-2xl font-bold text-burgandy bg-white w-10 overflow-hidden text-center rounded py-3 px-1">
                00
                </h1>
               <p className="p-2">SECONDS</p>
           </div>
</div> 
              :

             <div className="p-3 timer fit bg-burgandy  flex  items-center  -translate-y-4  border-white justify-between  ">
                        <div className="flex items-center  w-24 flex-col">
                            <h1 className="text-2xl font-bold w-10 overflow-hidden text-burgandy text-center bg-white rounded py-3 px-1">
                                {days}
                            </h1>
                            <p className="p-2">DAYS</p>
                        </div>
                        <div className="flex items-center  w-24 flex-col">
                            <h1 className="text-2xl font-bold text-burgandy bg-white w-10 text-center overflow-hidden rounded py-3 px-1">
                                {hours}
                            </h1>
                            <p className="p-2">HOURS</p>
                        </div>
                        <div className="flex items-center  w-24 flex-col">
                            <h1 className="text-2xl font-bold text-burgandy bg-white w-10 text-center overflow-hidden rounded py-3 px-1">
                                {minutes}
                            </h1>
                            <p className="p-2">MINUTES</p>
                        </div>

                        <div className="flex items-center  w-24 flex-col">
                            <h1 className="text-2xl font-bold text-burgandy bg-white w-10 overflow-hidden text-center rounded py-3 px-1">{seconds}</h1>
                            <p className="p-2">SECONDS</p>
                        </div>
             </div> 

          }
       
       </>
        
    )
}