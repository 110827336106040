import React from "react";
import momoImg from "../assets/imgs/mtnmom.png";

export default function Modal({showModal,handleCancel}) {
  
    
   if(showModal){
     document.body.classList.add('active-modal');
   }else{
     document.body.classList.remove('active-modal');
   }

  return (
    <>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none"
          >
            <div className="relative w-5/6 my-6 mx-auto max-w-3xl z-50">
              {/*content*/}
              <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="border relative">
                 
                  <h3 className="text-3xl font-semibold text-burgandy text-center  py-5">
                   Gift  Couple
                  </h3>

                  <button
                    className="absolute top-0 right-2  text-black  text-3xl leading-none font-semibold outline-none md:top-0 md:right-4 focus:outline-none"
                    onClick={handleCancel}
                  >
                    <span className=" p-1 text-black text-3xl block text-center hover:text-slate-600 active:text-slate-300">
                      ×
                    </span>
                  </button>

                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto font-poppins text-lg">
                  <p className="text-base text-center intro-text md:text-lg">
                     Thank You For Being Part Of Our Lives.
                     You Can Gift Us Via,
                  </p>
                  <div className="flex items-center flex-col justify-center p-3 md:flex-row">
                  <img 
                     src={momoImg} 
                     alt="momo icon"
                     className="w-16 h-16 object-contain align-middle p-2"
                  />
                    <ul className=" text-center space-y-1 md:space-y-3">
                      <li> Name : Stephen Ntim </li> 
                      <li > MoMo Number : <span className="text-2xl">0246561542</span>  </li>
                      <li>Carrier : MTN</li>
                    </ul>
                   </div>
                   <p className="text-center text-sm text-slate-600">
                    <strong className="text-black">NB: </strong>
                     Gift Tables will be available at all events.
                    </p>
                  
                  
                </div>

                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
               
                  <button
                    className="bg-burgandy text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg hover:opacity-80 active:opacity-10 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleCancel}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
            {/* Overlay */}
            <div className="opacity-25 fixed inset-0 z-40 bg-black cursor-pointer overflow-y-hidden" onClick={handleCancel}>

            </div>
          </div>

         
        </>
      ) : null}
    </>
  );
}
