import Hero from "./Home/Hero";
import Invitation from "./Home/Invitation";
import GroomBride from "./Home/GroomBride";
import OurStory from "./Home/OurStory";
import WeddingEvents from "./Home/WeddingEvents";
import ReceptionOutline from "./Home/ReceptionOutline";
import Gallery from "./Home/Gallery";
import Rsvp from "./Home/Rsvp";
import { Helmet } from "react-helmet";
import Footer from "./Footer";

const Home = () => {
     return (
      <>
          <Helmet>
                <title>
                    Stephen & Bruwaa Wedding
                </title>
                <meta name="keywords" content=" Stephen Bruwaa Stephen&Bruwaa Stephen Ntim Winnifred Bruwaa" />
                <meta name="description" content="The wedding of Stephen Ntim and Winnifred Bruwaa  dated for 15th April 2023"/>
            </Helmet>
         <Hero />
         <Invitation />
         <GroomBride />
         <OurStory />
         <WeddingEvents />
         <ReceptionOutline/>
         <Gallery />
         <Rsvp />
         <Footer type="normal"/>
      </>
        
     )
}

export default Home;