import "./../styles.css";
import inviImg from "../../assets/imgs/invitation.jpg"
import wedIconBurg from "../../assets/imgs/sb-logo.png";

import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const Invitation = () => {
    useEffect(()=>{
        AOS.init();
    },[])
    return (
        <section className="bg-white overflow-hidden" aria-label="wedding invitation">
            {/* Invitation container */}
           <div className="container h-screen bg-white flex w-5/6 mx-auto short:h-auto">
            
            {/* Side Image */}
            <div className="img hidden md:block flex-1 h-full short:h-auto">
              <img
                src={inviImg}
                alt="invitation"
                className="w-full h-full object-cover"
              />
            </div>

            {/* Side Text */}
            <div className="invitation-text flex-1 h-full flex items-center justify-center space-y-6 flex-col p-6 text-center bg-zinc-200 md:space-y-8 short:h-auto ">
                <div className="intro w-full lg:w-2/3 pt-8 space-y-6">

                    <h1 className="text-center text-burgandy font-bold text-2xl font-sans " >
                        Together with our families
                    </h1>

                    <h1 className="text-center text-darkGold font-sans text-base md:text-lg" data-aos="fade-up">
                        CORDIALLY INVITE YOU TO CELEBRATE THE SOLEMNIZATION OF HOLY MATRIMONY OF  
                    </h1>

                </div>

                <div className="couple-names">
                    <h1 className="font-poppins text-6xl text-burgandy tracking-tight md:text-7xl " data-aos="fade-up">
                         <p> Stephen </p> 
                               & 
                         <p> Bruwaa </p>
                    </h1>
                </div>

                <div className="w-24">
                    <img src={wedIconBurg} alt="wedding icon" className="max-w-full w-full" data-aos="fade-up"  />
                </div>

                <div className="desc text-sm font-poppins text-darkGold">
                    <h1 className="p-1 font-poppins" data-aos="fade-up">
                        WHICH TAKES PLACE ON 
                    </h1>
                    <h1 className="text-xl p-1 tracking-wide md:text-2xl" data-aos="fade-up">
                            APRIL | 15 | 2023
                    </h1>
                    <h1 className="p-1 tracking-wider" data-aos="fade-up">
                        STARTING AT 10:30 AM
                    </h1>
                </div>   
                
                <a  
                    href="https://goo.gl/maps/UpL2Jb1Pmv3EDAY97"
                        target="_blank"
                        rel="noreferrer"
                        className="block text-darkGold underline pb-2 w-full font-poppins md:w-2/3"
                       
                        >  
                            <p className="tracking-wider p-1">
                                    Church Of Pentecost 
                                    B.K. Arthur Memorial Temple, Oduom - Kumasi.
                            </p>
                </a>  
 
             
            </div>

             </div>
        </section>
       
    )
    
}

export default Invitation;