import bgImg from "../../assets/imgs/flower_bg.jpg";
import "./../styles.css";
import wedIconWhite from "../../assets/imgs/sb-white.png";
import Countdown from "react-countdown";
import {CountDownRender1} from "../CountdownRenders";
import { WEDDING_DATE as weddingDate } from "../../globals";


const Hero = () => {


    return (
        <section className="flex items-center justify-center relative text-center" aria-label="wedding introduction">
            {/* Hero Img */}
            <img
              src={bgImg}
               alt="wedding-img" 
               className="w-screen h-screen fixed img-cover top-0 left-0 -z-10 object-cover short:h-auto" 
            />
            {/* Hero Overlay Text */}
            <div className="flex flex-col items-center  space-y-16 h-screen w-full text-white bg-lightDark md:space-y-12 short:h-auto">
                
                {/* Wedding Icon  */}
                <div className="img w-fit mt-4">
                    <img 
                     src={wedIconWhite} alt="wedding icon" 
                     className="align-middle w-32 h-32 object-cover" />
                </div>

                <div className="flex w-2/3  items-center justify-around md:w-1/2">

                  <span className="block w-1/6 bg-white h-0.5 "> </span>
                  <h1 className="text-xl sm:text-2xl">THE WEDDING OF</h1> 
                  <span className="block w-1/6 bg-white h-0.5 "> </span>
                  
                </div>
                
                <div className="couple-names">
                    <h1 className="font-vibes text-5xl tracking-wide sm:text-6xl md:text-7xl md:tracking-widest lg:text-8xl">
                        Stephen & Bruwaa
                    </h1>
                </div>

                {/* CountDown  */}

                {/* <div className=" mt-8 p-3 timer w-5/6 flex items-center justify-between sm:w-4/6 md:w-2/6">
                    <div className="count">
                        <h1 className="text-2xl font-bold">-41</h1>
                        <p className="p-2">DAYS</p>
                    </div>
                    <div className="count">
                        <h1 className="text-2xl font-bold">22</h1>
                        <p className="p-2">HOURS</p>
                    </div>
                    <div className="count">
                        <h1 className="text-2xl font-bold">09</h1>
                        <p className="p-2">MINUTES</p>
                    </div>
                    <div className="count">
                        <h1 className="text-2xl font-bold">33</h1>
                        <p className="p-2">SECONDS</p>
                    </div>
                </div> */}

                <Countdown date={weddingDate} renderer={CountDownRender1} />
            </div>

        </section>
    )
}
export default Hero;
