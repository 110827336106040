import './App.css';
// import { useEffect, useState } from 'react';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import Navbar from "./components/Navbar";
import Home from './components/Home';
import OurGallery from './components/OurGallery';
import WatchLive from './components/WatchLive';
import Error404 from "./components/Error404";

function App() {
 
  return (
    <Router>
      <Navbar />
      <Routes>
         <Route path="/" element={<Home/>} />
         <Route path="/gallery" element={<OurGallery/>}/>
         <Route path="watch-live" element={<WatchLive/>} />
         <Route path="*" element={<Error404 />} />
      </Routes>
    </Router>
  );
}


export default App;
