import { useEffect, useState } from "react";

const Footer = ({type}) => {
    const [footerType,setFooterType] = useState(type);
    useEffect(()=>{
        setFooterType(type)
    },[footerType,type])
    return (
        <footer>
            {footerType==="normal" ?
            <div className="bg-burgandy p-7">
                <div className="text-center">
                    <h1 className="text-white font-sofia text-3xl py-2">S & B</h1>
                    <p className="text-white text-sm">&copy; copyright 2023</p>
                </div>

                <div className="text-sm text-white text-center mt-5 md:mt-3 lg:text-right text-gray-300 ">
                    developed by oseiclement &nbsp;
                    <span>
                     <a 
                         href="https://wa.me/233559751859"
                         target="_blank"
                         rel="noreferrer"
                         className="underline"
                         >        
                         <span> 
                              +233559751859 
                         </span>    
                     </a>
                    </span>
                </div>
                
                      
           </div>
           :
           <div className="bg-white p-7">
            <div className="text-center">
                <h1 className="text-burgandy font-sofia text-3xl py-2">S & B</h1>
                <p className="text-burgandy text-sm">&copy; copyright 2023</p>
            </div>
            <div className="text-sm text-slate-800 text-center mt-5 md:mt-3 lg:text-right  ">
                    developed by oseiclement &nbsp;
                    <span>
                     <a 
                         href="https://wa.me/233559751859"
                         target="_blank"
                         rel="noreferrer"
                         className="underline"
                         >        
                         <span> 
                              +233559751859 
                         </span>    
                     </a>
                    </span>
                </div>
                
        </div>

        }
        </footer>
        
    )
}

export default Footer;